<template>
  <div :class="isMobile ? 'm-about':'about'">
    <div :class="isMobile ? 'm-about-banner' : 'about-banner'">
      <img src="../assets/image/about/about-banner.png" alt="">
      <div class="white" :class="isMobile ? 'm-about-item-text':'about-item-text'">
        <div :class="isMobile ? 'm-box-all':'box-all'">
          <div :class="isMobile ? 'm-box-foot':'box-foot'"></div>
        </div>
        <div :class="isMobile ? 'm-title':'title'">关于我们</div>
        <div :class="isMobile ? 'm-description':'description'">在Crossea，我们致力于提供全面的国际贸易服务，帮助企业在全球市场中蓬勃发展。凭借丰富的经验和深厚的行业知识，我们为客户提供量身定制的解决方案，以满足其独特的需求。</div>
      </div>
    </div>
    <div :class="isMobile ? 'm-service':'service'">
      <div :class="isMobile ? 'm-service-title':'service-title'">我们的服务</div>
      <div v-if="!isMobile">
        <div class="grid" v-for="(item, index) in list" :key="index">
          <div class="left" :style ="{float : item.direction}">
            <img :src="item.Img" alt="">
          </div>
          <div class="right" :style ="{float : item.direction}">
            <div>
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="m-grid">
        <ul v-for="(item, index) in list" :key="index">
          <li>
            <img :src="item.Img" alt="">
            <p class="title">{{ item.title }}</p>
            <p class="desc">{{ item.desc }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div :class="isMobile ? 'm-introduce': 'introduce'">
      <div :class="isMobile ? 'm-introduce-text': 'introduce-text'">
        <p>在Crossea，我们以卓越、诚信和客户满意为荣。我们深知每个企业的独特性，与客户密切合作，制定个性化的战略，以实现其目标和愿景。凭借我们的专业知识和个性化的方法，我们努力成为您国际贸易道路上的值得信赖的合作伙伴。</p>
        <p>立即与我们联系，了解更多关于Crossea如何帮助您的企业在全球市场取得成功的信息。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobileDevice } from '../utils/detectDevice';
import GJMY from '../assets/image/about/gjmy.png'
import SCFX from '../assets/image/about/scfx.png'
import MYLY from '../assets/image/about/myly.png'
import ZHCG from '../assets/image/about/zhcg.png'
import WLJJFA from '../assets/image/about/wljjfa.png'
export default {
  name: "about",
  data() {
    return {
      isMobile: isMobileDevice(),
      GJMY,
      SCFX,
      MYLY,
      ZHCG,
      WLJJFA,
      list: [
        { direction: 'left', Img: GJMY, title: '国际贸易', desc: '我们通过连接企业与全球可靠的贸易伙伴，促进无缝的跨境交易。我们的团队协助进行谈判、合同管理和贸易合规，确保运营顺畅，利润最大化。'},
        { direction: 'right', Img: SCFX, title: '市场分析', desc: '我们的专业分析师进行深入研究，提供有价值的市场趋势、消费者行为和竞争环境洞察。这使得我们的客户能够做出明智决策，发现新机会，并在快速变化的商业环境中保持领先地位。'},
        { direction: 'left', Img: MYLY, title: '贸易履约', desc: '我们负责整个贸易履约过程，从产品采购和质量控制到包装和运输。我们精细的注意细节确保货物按时交付并处于最佳状态，为客户提供安心感和竞争优势。'},
        { direction: 'right', Img: ZHCG, title: '智慧采购', desc: '我们协助企业在全球范围内寻找可靠的供应商和制造商。通过我们广泛的网络和严格的供应商评估流程，我们确保客户以有竞争力的价格获得高质量的产品。我们的目标是帮助简化采购过程，并建立长期合作伙伴关系，推动增长。'},
        { direction: 'left', Img: WLJJFA, title: '物流解决方案', desc: '我们提供高效、具有成本效益的物流解决方案，根据每个客户的独特需求进行定制。从运输和清关到仓储和分销，我们的物流专家优化供应链运营，确保及时交付和客户满意度。'},
      ]
    }
  }

}
</script>

<style scoped lang="less">
.about {
  width: 100%;
  height: 100%;
  color: pink;
  .about-banner {
    width: 100%;
    height: 960px;
    position: relative;
    img {
      width: 100%;
      height: 960px;
    }
    .about-item-text {
      position: absolute;
      top: 30%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      width: 1280px;
      margin: 0 auto;
      color: white;
      .title {
        font: 80px AlimamaShuHeiTi;
        font-weight: 700;
      }
      .description {
        width: 960px;
        font-size: 30px;
        font-weight: 400;
        margin: 60px auto 0;
      }
      .box-all{
        width: 180px;
        height: 160px;
        border-top: 8px solid #ffffff;
        border-bottom: 8px solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -10%;
        left: 50%;
        transform: translateX(-50%);
      }
      .box-all:before, .box-all:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        top: 0;
      }
      .box-all:before, .box-foot:before{
        border-left: 8px solid #ffffff;
        left: 0px;
      }
      .box-all:after,.box-foot:after{
        border-right: 8px solid #ffffff;
        right: 0;
      }
      .box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .box-foot:before, .box-foot:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        bottom: 0;
      }
    }

  }
  .service{
    margin: 100px auto;
    .service-title {
      width: 1280px;
      font: 60px AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin: 0px auto 100px;
    }
    .grid {
      width: 1280px;
      height: 400px;
      margin: 0 auto;
      >div {
        width: 50%;
        height: 100%;
      }
      .left img {
        width: 100%;
        height: 100%;
      }
      .title {
        font-size: 30px;
        font-weight: 400;
        color: #121212;
        width: 420px;
        margin: 80px auto 40px;
      }
      .desc {
        font-size: 17px;
        font-weight: 400;
        color: #121212;
        width: 420px;
        margin: 0 auto;
        line-height: 30px;
      }
    }
  }
  .introduce {
    width: 100%;
    height: 480px;
    background: url("../assets/image/about/about-bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .introduce-text {
      width: 1280px;
      font-size: 20px;
      color: #ffffff;
      margin: 0 auto;
      line-height: 40px;
      p:last-child {
        margin-top: 35px;
      }
    }
  }

}
.m-about {
  width: 100vw;
  height: 100%;
  color: pink;
  .m-about-banner {
    width: 100%;
    height: 5rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .m-about-item-text {
      position: absolute;
      top: 30%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      margin: 0 .8rem;
      .m-box-all{
        width: 1.25rem;
        height: 1rem;
        border-top: .05rem solid #ffffff;
        border-bottom: .05rem solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -7.5%;
        left: 50%;
        transform: translateX(-50%);
      }
      .m-box-all:before, .m-box-all:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        top: 0;
      }
      .m-box-all:before, .m-box-foot:before{
        border-left: .05rem solid #ffffff;
        left: 0px;
      }
      .m-box-all:after,.m-box-foot:after{
        border-right: .05rem solid #ffffff;
        right: 0;
      }
      .m-box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .m-box-foot:before, .m-box-foot:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        bottom: 0;
      }
      .m-title {
        font-size: .5rem;
        font-family: AlimamaShuHeiTi;
      }
      .m-description {
        font-size: .24rem;
        margin: .5rem auto 0;
        width: 99%;
      }
    }
  }
  .m-service {
    width: 100vw;
    margin: .5rem auto;
    .m-service-title {
      font: .55rem AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin: 0px auto .5rem;
    }
    .m-grid {
      width: 90%;
      margin:  0 auto;
      ul li {
        width: 100%;
        text-align: center;
        color: #121212;
        img{
          width: 100%;
          height: 5rem;
        }
        .title {
          width: 80%;
          font-size: .38rem;
          font-weight: 400;
          color: #121212;
          margin: .3rem auto .2rem;
        }
        .desc {
          width: 85%;
          font-size: .28rem;
          font-weight: 400;
          color: #121212;
          margin: 0 auto .5rem;
          line-height: .38rem;
        }
      }

    }
  }
  .m-introduce {
    width: 100vw;
    height: 3.8rem;
    background: url("../assets/image/about/about-bg.png") no-repeat;
    background-size: auto 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .m-introduce-text {
      width: 90%;
      font-size: .25rem;
      color: #ffffff;
      margin: .5rem auto;
      line-height: .4rem;
      p:last-child {
        margin-top: .5rem;
      }
    }
  }
}
</style>
